import React from 'react'
import Layout from 'components/global/layout'
import SubPageHeader from 'components/shared/subPageHeader'
import PageIntro from 'components/global/pageIntro'
import { graphql } from 'gatsby'
import PhotographersWrapper from 'components/photographers/photographersWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const Photographers = ({ data }) => {
  const photographers = data.photographers.nodes
  return (
    <Layout>
      <SubPageHeader title="PHOTOGRAPHERS" text="VIEW ALL OUR">
        <StaticImage src="../images/photographs.png" />
      </SubPageHeader>
      <PageIntro
        title="Our Photographers"
        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, ut nemo architecto ipsum maiores ea, obcaecati reiciendis hic ipsa distinctio provident! Dignissimos quos doloremque modi."
      />
      <PhotographersWrapper data={photographers} />
    </Layout>
  )
}

export default Photographers

export const PhotoQuery = graphql`
  query PhotoQuery {
    photographers: allPhotographersJson {
      nodes {
        name
        img
      }
    }
  }
`
